import React from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import { Layout, Container } from 'layouts';
import { Header, TagsBlock } from 'components';
import config from '../../config/site';

const Tags = ({ data }) => {
  const tags = data.allMarkdownRemark.group;
  return (
    <Layout>
    <Helmet title={`Tags | ${config.siteTitle}`} />
      <Header title="Tags" />
      <Container>
        <TagsBlock list={tags} showNum={true} />
      </Container>
    </Layout>
  );
};

export default Tags;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;